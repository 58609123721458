import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    works: [],
  },
  getters: {
    GET_WORKS(state) {
      return state.works
    },
  },
  mutations: {
    SET_WORKS(state, value) {
      state.works = value
    },
  },
  actions: {
    getWorkList(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get('api/v1/works', payload).then(response => {
          // console.log(response.data)
          context.commit('SET_WORKS', response.data.data)
          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    getSelectedWork(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`api/v1/works/${payload.work_id}`).then(response => {
          // context.commit('SET_WORKS', response.data.data)
          resolve(response.data)
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    storeWork(context, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axiosIns.post('api/v1/works', payload).then(response => {
          // context.commit('SET_COMPANIES', response.data.data)

          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    editWork(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.patch(`api/v1/works/${payload.id}`, payload).then(() => {
          // console.log(response.data)
          // context.commit('SET_COMPANIES', response.data.data)
          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    deleteWork(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`api/v1/works/${payload}`).then(() => {
          // console.log(response.data)
          // context.commit('SET_COMPANIES', response.data.data)
          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
  },
}
