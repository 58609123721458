import company from '@/store/user/company'
import work from '@/store/user/work'
import task from '@/store/user/task'
import users from '@/store/user/users'
import comment from '@/store/user/comment'
import dashboard from '@/store/user/dashboard'
import file from '@/store/user/file'
import axiosIns from '../../libs/axios'

export default {
  namespaced: true,
  modules: {
    company,
    work,
    task,
    users,
    comment,
    dashboard,
    file,
  },
  state: {
    user: {},
  },
  getters: {
    getUserData(state) {
      return state.user
    },
  },
  actions: {
    handleLogin(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post('api/v1/login', payload).then(response => {
          // console.log(response.data.data.access_token)
          // localStorage.removeItem('userData')
          localStorage.setItem('_t', response.data.data.access_token)

          // localStorage.setItem('_u', JSON.stringify(null))
          // context.commit('setUserData', response.data.userData)
          resolve('success')
        }).catch(error => {
          reject(error)
        })
      })
    },
    handleFileUpload(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post('api/v1/file', payload).then(response => {
          // console.log(response)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    account(context) {
      return new Promise((resolve, reject) => {
        axiosIns.get('api/v1/account').then(response => {
          // localStorage.removeItem('_u')
          // localStorage.setItem('userAccessToken', response.data.data.access_token)
          localStorage.setItem('_u', JSON.stringify(response.data.userData))
          context.commit('SET_USER_DATA', response.data.userData)
          resolve('success')
        }).catch(error => {
          localStorage.removeItem('_u')
          localStorage.removeItem('_t')
          reject(error)
        })
      })
    },

  },
  mutations: {
    SET_USER_DATA(state, value) {
      state.user = value
      // console.log(value)
    },
  },
}
