import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    files: [],
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    deleteFile(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`api/v1/file/${payload.id}`).then(() => {
          // console.log(response.data)
          // context.commit('SET_COMPANIES', response.data.data)
          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
  },
}
