import Vue from 'vue'
import VueRouter from 'vue-router'
import { getUserData, isUserLoggedIn } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        userType: ['admin', 'manager', 'executive', 'accounts'],
        authUser: true,
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/company/list',
      name: 'company.list',
      component: () => import('@/views/company/Index.vue'),
      meta: {
        userType: ['admin', 'manager'],
        authUser: true,
        pageTitle: 'Company List',
        breadcrumb: [
          {
            text: 'Company',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/work/list',
      name: 'work.list',
      component: () => import('@/views/work/Index.vue'),
      meta: {
        userType: ['admin', 'manager'],
        authUser: true,
        pageTitle: 'Work',
        breadcrumb: [
          {
            text: 'All list',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user/list',
      name: 'user.list',
      component: () => import('@/views/user/Index.vue'),
      meta: {
        userType: ['admin', 'manager'],
        authUser: true,
        pageTitle: 'User List',
        breadcrumb: [
          {
            text: 'User',
            active: true,
          },
          {
            text: 'List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/task',
      name: 'tasks',
      component: () => import('@/views/task/Index.vue'),
      meta: {
        userType: ['admin', 'manager', 'executive', 'accounts'],
        authUser: true,
        pageTitle: 'Task List',
        breadcrumb: [
          {
            text: '',
            to: '',
            active: false,
          },
          {
            text: '',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/task/:name',
    //   name: 'tasklist',
    //   component: () => import('@/views/task/todo/Todo.vue'),
    //   meta: {
    //     contentRenderer: 'sidebar-left',
    //     contentClass: 'todo-application',
    //   },
    // },
    {
      path: '/task-comment/:name',
      name: 'task-chat',
      component: () => import('@/views/work/chat/Chat.vue'),
      meta: {
        userType: ['admin', 'manager', 'executive', 'accounts'],
        contentRenderer: 'sidebar-left',
        contentClass: 'chat-application',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        guestUser: true,
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authUser)) {
    const userData = getUserData()
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isUserLoggedIn()) {
      next({
        name: 'login',
        query: { redirect: to.fullPath },
      })
    } else {
      if (!to.meta.userType.includes(userData.role)) {
        localStorage.removeItem('_t')
        localStorage.removeItem('_u')
        console.log(to.meta.userType !== userData.role)
        next({
          name: 'login',
          query: { redirect: to.fullPath },
        })
      }
      next()
      const roles = ['admin', 'super_admin', 'manager']
      if (to.name === 'tasks') {
        to.meta.breadcrumb[0].text = roles.includes(userData.role) ? 'Work List' : 'Task'
        to.meta.breadcrumb[0].to = roles.includes(userData.role) ? '/work/list' : 'Task'
        to.meta.breadcrumb[1].text = roles.includes(userData.role) ? 'Task List' : 'List'
      }
    }
  } else if (to.matched.some(record => record.meta.guestUser)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isUserLoggedIn()) {
      next({
        name: 'home',
        // query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
