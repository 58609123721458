import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    users: [],
  },
  getters: {
    GET_USERS(state) {
      return state.users
    },
  },
  mutations: {
    SET_USERS(state, value) {
      state.users = value
    },
  },
  actions: {
    getUserList(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get('api/v1/users', payload).then(response => {
          context.commit('SET_USERS', response.data.data)
          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    getAssignees(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get('api/v1/get-assignees', payload).then(response => {
          context.commit('SET_USERS', response.data)
          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    storeUser(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post('api/v1/users', payload).then(() => {
          // console.log(response.data)
          // context.commit('SET_COMPANIES', response.data.data)
          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    editUser(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.patch(`api/v1/users/${payload.id}`, payload).then(() => {
          // console.log(response.data)
          // context.commit('SET_COMPANIES', response.data.data)
          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    deleteUser(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`api/v1/users/${payload}`).then(() => {
          // console.log(response.data)
          // context.commit('SET_COMPANIES', response.data.data)
          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
  },
}
