import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    GET_ITEMS(state) {
      return state.items
    },
  },
  mutations: {
    SET_ITEMS(state, value) {
      state.items = value
    },
  },
  actions: {
    getItems(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get('api/v1/dashboard', payload).then(response => {
          context.commit('SET_ITEMS', response.data)
          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
  },
}
