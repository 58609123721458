import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    comments: [],
  },
  getters: {
    getComments(state) {
      return state.tasks
    },
  },
  mutations: {
    SET_COMMENTS(state, values) {
      state.tasks = values
    },
  },
  actions: {
    GET_COMMENTS(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get('api/v1/comments', payload).then(response => {
          context.commit('SET_COMMENTS', response.data.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    SEND_COMMENT(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post('api/v1/comments', payload).then(() => {
          // context.commit('SET_COMMENTS', response.data.data)
          resolve('success')
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
