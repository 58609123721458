import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://back.classicalhometex.com',
  // baseURL: 'http://127.0.0.1:8000',
  timeout: 90000,
  headers: {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem('_t')}`
      },
    },
  },
})

Vue.prototype.$http = axiosIns

export default axiosIns
