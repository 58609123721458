import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    companies: [],
  },
  getters: {
    GET_COMPANIES(state) {
      return state.companies
    },
  },
  mutations: {
    SET_COMPANIES(state, value) {
      state.companies = value
    },
  },
  actions: {
    getCompanyList(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get('api/v1/companies', payload).then(response => {
          // console.log(response.data)
          context.commit('SET_COMPANIES', response.data.data)
          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    storeCompany(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post('api/v1/companies', payload).then(() => {
          // console.log(response.data)
          // context.commit('SET_COMPANIES', response.data.data)
          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    editCompany(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.patch(`api/v1/companies/${payload.id}`, payload).then(() => {
          // console.log(response.data)
          // context.commit('SET_COMPANIES', response.data.data)
          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    deleteCompany(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`api/v1/companies/${payload}`).then(() => {
          // console.log(response.data)
          // context.commit('SET_COMPANIES', response.data.data)
          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
  },
}
