import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    tasks: [],
    task_type: [],
  },
  getters: {
    getTasks(state) {
      return state.tasks
    },
    getTaskType(state) {
      return state.task_type
    },
  },
  mutations: {
    SET_TASKS(state, values) {
      state.tasks = values
    },
    SET_TASK_TYPE(state, values) {
      state.task_type = values
    },
  },
  actions: {
    GET_TASK_DETAILS(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`api/v1/tasks/${payload.task_id}`).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    GET_TASKS(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.get('api/v1/tasks', payload).then(response => {
          context.commit('SET_TASKS', response.data.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    GET_TYPE(context) {
      return new Promise((resolve, reject) => {
        axiosIns.get('api/v1/task-type').then(response => {
          context.commit('SET_TASK_TYPE', response.data.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    STORE_TASK(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.post('api/v1/tasks', payload).then(response => {
          // context.commit('SET_TASKS', response.data.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    EDIT_TASK(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.patch(`api/v1/tasks/${payload.id}`, payload).then(() => {
          // console.log(response.data)
          // context.commit('SET_COMPANIES', response.data.data)
          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    DELETE_TASK(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.delete(`api/v1/tasks/${payload}`).then(() => {
          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    UPDATE_STATUS(context, payload) {
      return new Promise((resolve, reject) => {
        axiosIns.patch(`api/v1/tasks/update-status/${payload.id}`, payload).then(() => {
          resolve('success')
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
  },
}
